.parent_about {
    display: flex;
    flex-flow: column wrap;
    overflow-x: hidden;
}

.child_about {
    padding-top: 18vh;
    padding-bottom: 15vh;
    padding-left: 1vh;

    display: flex;
    flex-flow: column ;
    justify-content: space-between;
    min-height: 50vh;
}

.section_divide_first_about{

    display: flex;
    flex-flow: column;
    width: 95%;

}

.section_divide_first_about h1 {
    font-family: 'Staatliches';
    font-size:5vh;
    margin: 0.5vh;
    color: #5d5d5e;
    text-decoration: underline;
    letter-spacing: 2px


}

.section_divide_first_about h2 {
    font-family: 'Staatliches';
    color: #1c1d20;
    letter-spacing: 1px;
    font-size: 3.5vh;
}

.section_divide_first_about h3 {
    font-family: 'Staatliches';
    letter-spacing: 1px;
    color: #1c1d20;
}

.section_divide_second_about{

    padding-top: 20vh;
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;


}

.trustIMG {
    width: 150px;
}

.box_1 {
    position: absolute;
    width: 300px;
    z-index: -1;
padding-left: 220px;
padding-bottom: 100px;

}

.box_2 {
    position: absolute;
    width: 300px;
    z-index: -1;
    padding-right: 150px;
    padding-bottom: 100px;
}

.box_3 {
    position: absolute;
    width: 300px;
    z-index: -2;
    padding-left: 40px;
    padding-bottom: 200px;
}

@media only screen and (min-width: 768px) {



    .child_about {

        flex-flow: row ;
        padding-left: 7vh;

    }

    .section_divide_first_about{
        width: 50%;
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
    }

    .section_divide_second_about{


        width: 50%;



    }


    .box_1 {
        position: absolute;
        width: 400px;
        z-index: -1;
    padding-left: 350px;
    padding-bottom: 100px;

    }

    .box_2 {
        position: absolute;
        width: 400px;
        z-index: -1;
        padding-right: 250px;
        padding-bottom: 100px;
    }

    .box_3 {
        position: absolute;
        width: 400px;
        z-index: -2;
        padding-left: 50px;
        padding-bottom: 200px;
    }

    .section_divide_second_about{
        padding-top: 0vh;


    }
  }
