

/* For mobile phones: */


/* @font-face {
      font-family: 'Staatliches';
      src: local('Staatliches'), url("../../fonts/Staatliches-Regular.ttf") format('truetype');
    } */



.navbar {
        position: relative;
        display: flex;
        flex-direction: column  ;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 25px;

        /* background-color: white; */
        /* background-image: url("../../assets/peakpx.jpg");
        background-size: auto; */
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(5deg);
        }
      }

      @keyframes zoom-in {
        0% {
          transform: scale(1, 1);

        }
        100% {
          transform:  scale(1.05, 1.05);

        }

      }

      @keyframes zoom-out {
        0% {
          transform: scale(1.05, 1.05);

        }
        100% {
          transform:  scale(1, 1);

        }

      }


.maxTehnika {
      padding: 2.5vh;
      margin-left: 10px;
      width:80vw;
      height: 12vh;
      animation-name: zoom-out;
      animation-duration:2s;
    }

.maxTehnika:hover {
  /* animation: zoom-in-zoom-out 2s reverse ; */
  animation-name: zoom-in;
  animation-duration:2s;

  animation-fill-mode: forwards;
}






.navigation {
      /* background-color: #162D3D; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style: none;
        align-items: center;

        width: 100%;
        padding: 2px;
        margin-top: 20px;

        /* background-image: url("../../assets/peakpx.jpg"); */
    }

.menuMobile{
      display: flex;
      flex-direction: column;
      justify-content: top;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      width: 100%;
      /* min-height: 400px; */
      margin-top: 40px;
    }

    @keyframes slide-top {
      0% {
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
      }
      100% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
      }
    }

.mobileNavigation li:nth-child(1){
    animation: slide-top 0.8s 1 ;
}

.mobileNavigation li:nth-child(2){
  animation: slide-top 1s 1 ;
}

.mobileNavigation li:nth-child(3){
  animation: slide-top 1.2s 1 ;
}

.mobileNavigation li:nth-child(4){
  animation: slide-top 1.4s 1 ;
}
.mobileNavigation li:nth-child(5){
  animation: slide-top 1.6s 1 ;
}

.mobile_span_1{
  animation: slide-top 0.8s 1 ;
}



.mobile_span_2{
animation: slide-top 1s 1 ;
}

.mobile_span_3{
animation: slide-top 1.2s 1 ;
}

.mobile_span_4{
animation: slide-top 1.4s 1 ;
}
.mobile_span_5{
animation: slide-top 1.6s 1 ;
}
.mobile_span_6{
  animation: slide-top 1.8s 1 ;
  }

  .mobile_span_1_language{
    animation: slide-top 1s 1 ;
  }

  .mobile_span_2_language{
    animation: slide-top 1.2s 1 ;
  }
  .mobile_span_3_language{
    animation: slide-top 1.4s 1 ;
  }




.list_class {
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
      /* background-color: #162D3D; */
      /* background-image: url("../../assets/background.jpg"); */
    }

.navigation li {
    /* align-content: center; */
       /* background-color: #162D3D; */
      /* background-image: url("../../assets/background.jpg"); */
    }


.navigation a {
        text-decoration: none;
        display: block;

        color: #5d5d5e;
        font-family: 'Staatliches';
        font-size: 4.5vh;
        text-align: center;
      }

.navigation a:hover {
        color: #1c1d20;
        text-decoration: underline;
      }

      .navigation p, p:link, p:visited {
        text-decoration: none;
        display: block;
        padding-left: 0.5em;
        padding-right: 0.5em;
        color: #5d5d5e;
        font-family: 'Staatliches';
        font-size: 4.5vh;
        text-align: center;
        position: relative;
        padding-bottom: 3px; /* The distance between text and underline */
      }

      .navigation  p::before {
        content: "";
        position: absolute;
        width: 80%;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(90deg, #1c1d20, #1c1d20); /* underline color */
        background-size: 0 3.5px; /* vertical size of underline */
        background-repeat: no-repeat;
        background-position: left bottom; /* start position of underline */
        transition: background-size .3s ease-in; /* duration and timing style of animation */
      }

      .navigation  p:hover::before {
        background-size: 100% 3.5px;
        color:#1c1d20;
      }

      .navigation  p:hover{
        color:#1c1d20;
      }


.icon_language{
        padding: 1.2vh;
        display: flex;
        flex-flow: row;

      }

.icon_language_current{
        margin-top: 1.2vh;
        margin-left: 1vh;
        color: #1c1d20;
      }

      /* .icon_language :hover{
        color: #1c1d20;

      } */

.icon_meni {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }

.product_meni{
        display: flex;
        flex-direction: column;

      }

.navbar_span{
        /* visibility: hidden; */

        text-decoration: none;
        display: block;
        padding-left: 0.9em;
        padding-right: 0.9em;
        color: #5c5c5c;
        font-family: 'Staatliches';
        font-size: 3vh;
        text-align: justify;

      }

.navbar_span:hover  {
        color: #1c1d20;
        text-decoration: underline;
        text-decoration-thickness: 2.5px;
      }

.language_div {
  /* padding: 40%; */
  margin: auto;
  width: 50%;
       }

/*
       @keyframes span1 {
        100%{
          transform: translateY(0px) rotate(45deg);
          width: 40px;
      }}

      @keyframes span2 {
        100%{
          transform: translateY(0px) rotate(315deg);
      }}

      @keyframes span3 {
        100%{
          transform: translateX(60px);
      }} */



.toogle {
  position: relative;
  width: 70px;
  height: 70px;
  background: #5c5c5c52;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.352);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

}

.toogle span {
  position: absolute;
  width: 40px;
  height: 4px;
  background-color: #1c1d20;
  border-radius: 4px;
  transition: 0.5s;

}

.toogle span:nth-child(1){
  transform: translateY(-15px);
  width: 25px;
  left: 15px;

}

.toogle span:nth-child(2){
  transform: translateY(15px);
  width: 15px;
  left: 15px;
}


.toogle.active span:nth-child(1){
  transform: translateY(0px) rotate(45deg);
  width: 40px;
  transition-delay: 0,125s;
}

.toogle.active span:nth-child(2){
  transform: translateY(0px) rotate(315deg);
  width: 40px;
  transition-delay: 0,25s;
  animation-duration: 0,25s;
}
.toogle.active span:nth-child(3){
  transform: translateX(60px);
}






@media only screen and (min-width: 767px) {
        .navbar{
          display: flex;
          flex-direction: row  ;
          justify-content: space-between;
          padding-bottom: 12vh;




        }

        .navigation {

            /* background-color: #162D3D; */
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              list-style: none;



          padding: 0px;
          margin-top: 0px;

              /* background-image: url("../../assets/peakpx.jpg"); */


          flex-flow: row wrap;
          padding-right: 100px;

          margin-left: 100px;
        }

        .navigation li {
          padding-left: 15px;
          }



        .navbar_span {
          margin-top: -30px;
          position: absolute;

          /* width: 250px;
          height: 30px; */
         }

         .maxTehnika {
          padding: 2vh;
          width:60vh;
          height: 12vh;
        }



        .list_class {
          margin-top:  -50px;
        }

        .navigation a {
          text-decoration: none;
          display: block;
          padding-left: 0;
          padding-right: 0;
          color: #5d5d5e;
          font-family: 'Staatliches';
          font-size: 4.5vh;
          text-align: center;
        }

      .navigation a:hover {
          color: #1c1d20;
          text-decoration: underline;
        }

        .language_div {
          margin: auto;
          width: 100%;
          padding: 0;
               }


               .icon_meni {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
              }

      }

