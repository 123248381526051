
.parent_all_product {
    display: flex;
    flex-flow: column wrap;
}

.child_all_product {
    padding-top: 18vh;
    padding-bottom: 15vh;
    /* padding-left: 7vh;
    padding-right: 7vh; */
    display: flex;
    flex-flow: column ;
    justify-content: space-between;
    min-height: 50vh;

}

.h1_all_product {
    font-family: 'Staatliches';
    font-size:5vh;
    /* margin: 0.5vh; */
    color: #1c1d20;
    text-decoration: underline;
    padding-bottom: 5vh;
}

.image_div{
    max-width: 800px;
    min-width: 200px;

}


.image_part{
    width: 100%;
    height: auto;
    filter: drop-shadow(10px 5px 7px black);
}

@media only screen and (min-width: 767px) {

    .child_all_product {

        padding-left: 7vh;
        padding-right: 7vh;


    }


    .h1_all_product {

        margin: 0.5vh;

    }

}
