.parent_reparacija {
    display: flex;
    flex-flow: column wrap;
}

.child_reparacija {
    padding-top: 18vh;
    padding-bottom: 15vh;
    padding-left: 2vh;
    display: flex;
    flex-flow: column ;
    justify-content: space-between;

}

.section_divide_first_reparacija{

    display: flex;
    flex-flow: column;
    width: 95%;

}

.section_divide_first_reparacija h1 {
    font-family: 'Staatliches';
    font-size:5vh;
    margin: 0.5vh;
    color: #5d5d5e;
    text-decoration: underline;
    letter-spacing: 2px


}

.section_divide_first_reparacija h2 {
    font-family: 'Staatliches';
    color: #1c1d20;
    letter-spacing: 1px;
    font-size: 3.5vh;



}

.section_divide_first_reparacija h3 {
    font-family: 'Staatliches';
    letter-spacing: 1px;
    color: #1c1d20;
}

.section_divide_second_reparacija{
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 10vh;
    /* padding-top: 20vh;
    display: flex;
    flex-flow: column; */
    width: 90%;
    /* margin: auto;
    justify-content: center;
    align-items: center;  */
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr  ;
    grid-template-rows: 1fr 1fr 1fr 1fr ;
    column-gap: 20px;
    row-gap: 20px;


  }
  .item {

    background-color: white;
    margin: auto;
  }



.image_div_carousel {

    filter: drop-shadow(4px 5px 7px black);
}
.carouselImg {
    width:  100px;
    height: 100px;
    object-fit: cover;

    /* height: auto; */
}

.carouselImg_blur {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}


.selected_image {
    z-index: -1;
    object-fit: cover;

    max-width: 100%;
    height: auto;
    /* position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 50vh; */
}


.selected_image_div{

    width: 80vw;
    height: 50vh;

    display: flex;
    justify-content: center;
    position: absolute;


}

.show_img{
    z-index: 2;
    opacity: 1;

    transition: all 0.4s;
    filter: drop-shadow(4px 5px 7px black);
    transform: scale(1);
}

.hide_img{
    opacity: 0;
    z-index: -2;
    transition: all 0.4s;

    transform: scale(0.1);
}




@media only screen and (min-width: 768px) {

    .child_reparacija {
        padding-left: 7vh;
        flex-flow: row ;

    }

    .selected_image_div {

        width: 40vw;
        height: 40vh;
        }

    .section_divide_first_reparacija{
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
        width: 50%;
    }


    .section_divide_second_reparacija{
        padding-top: 0vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        width: 50%;

    }



    .carouselImg {
        width: 100%;
        margin: auto;
    }



        .selected_image {
            z-index: -1;
            object-fit: cover;
            max-width: 100%;
            height: auto;
            /* width: 40%;
            height: 100%; */
            /* position: absolute;
            object-fit: cover;
            width: 100vw;
            height: 50vh; */
        }

    .wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr  ;
            grid-template-rows: 1fr 1fr 1fr   ;
          }

          .show_img{
            z-index: 2;
            opacity: 1;

            transition: all 0.4s;
            filter: drop-shadow(4px 5px 7px black);
            transform: scale(1);
        }

        .hide_img{
            opacity: 0;
            z-index: -2;
            transition: all 0.4s;

            transform: scale(0.1);
        }

  }
