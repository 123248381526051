.parent_contact {
    display: flex;
    flex-flow: column wrap;
}

.child_contact {
    padding-top: 18vh;
    padding-bottom: 15vh;
    padding-left: 2vh;
    display: flex;
    flex-flow: column ;
    justify-content: space-between;
}

.mapouter {
    width: 100%;
    height: 100%;
}
.gmap_canvas{
    width: 100%;
    height: 100%;
}
.gmap_iframe{
    width: 100%;
    height: 100%;
}

.section_divide_first_contact{
    width: 95%;
    display: flex;
    flex-flow: column;
}

.section_divide_first_contact h1 {
    font-family: 'Staatliches';
    font-size:5vh;
    margin: 0.5vh;
    color: #1c1d20;
    text-decoration: underline;

}

.section_divide_first_contact h2 {
    font-family: 'Staatliches';
    color: #5d5d5e;
}

.section_divide_first_contact h3 {
    font-family: 'Staatliches';
    padding-left: 1.5vh;
    color: #1c1d20;
}

.section_divide_second_contact{
    display: flex;
    flex-flow: column;
    width: 95%;
    height: 400px;

}

.section_divide_second_contact a {

    margin: auto;
    margin-top: 0;
    margin-bottom: 0;

}

.section_divide_second_contact h1 {
    font-family: 'Staatliches';
    font-size:4vh;
    margin-right: 5vh;
    color: #1c1d20;
    text-decoration: underline;

}

.mapsPNG:hover {
    transform: translateY(-6px);
}


a {
    color:#1c1d20
}

h5{
    font-family:monospace ;
    font-size: large;
    margin-top: 0.5vh;
     margin-left: 2vh;
}

@media only screen and (min-width: 768px) {

    .child_contact {
        flex-flow: column ;
        justify-content: space-between;
        padding-left: 7vh;
    }

    .section_divide_first_contact{
        width: 100%;


    }

    .section_divide_second_contact{
        display: flex;
        flex-flow: column;
        width: 60%;
        height: 400px;

    }


    .section_divide_second_contact h1 {

        font-size:5vh;


    }

    .mapouter {
        width: 100%;
        height: 100%;
    }
    .gmap_canvas{
        width: 100%;
        height: 100%;
    }
    .gmap_iframe{
        width: 100%;
        height: 100%;
    }

  }




