

@font-face {
    font-family: 'Staatliches';
    src: local('Staatliches'), url("../../fonts/Staatliches-Regular.ttf") format('truetype');
  }

  @font-face {
    font-family: 'Lobster';
    src: local('Lobster'), url("../../fonts/Lobster-Regular.ttf") format('truetype');
  }

  @font-face {
    font-family: 'Orbitron-VariableFont_wght';
    src: local('Orbitron-VariableFont_wght'), url("../../fonts/Orbitron-VariableFont_wght.ttf") format('truetype');
  }

  @font-face {
    font-family: 'TitanOne';
    src: local('TitanOne'), url("../../fonts/TitanOne-Regular.ttf") format('truetype');
  }



.parent {
    display: flex;
    flex-flow: column ;
}

.section_logo_font{
    font-family: 'TitanOne';
    font-size:4vw;

    color: #5d5d5e;
}

.svg_div_animation{

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90%;
    height: 250px;

}

.questionSVG {
    width: 170px;
    padding: 50px;
}

.arrowUp {
    z-index: 5;
    position: fixed;
    bottom: 50px;
    right: 15px;
    width: 60px;
}


.child {
    padding-top: 15vh;
    padding-bottom: 12vh;
    /* padding-left: 2vh; */
    display: flex;
    flex-flow: column ;


}

.childNewColor {
    padding-top: 15vh;
    padding-bottom: 12vh;
    /* padding-left: 2vh; */
    display: flex;
    flex-flow: column ;
    /* background: rgb(255,187,14);
    background: linear-gradient(90deg, rgba(255,187,14,1) 0%, rgba(255,213,0,1) 100%); */

    background: rgb(255,227,32);
    background: linear-gradient(90deg, rgba(255,227,32,1) 0%, rgba(247,212,34,1) 100%);

    box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.8), 0 8px 25px 0 rgba(0, 0, 0, 0.1);

    /* box-shadow: 0 1px 2px rgba(0,0,0,0.15); */
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 50px;
}

.childQuestion{

    padding-top: 3vh;

    padding-left: 2vh;
    display: flex;
    flex-flow: column ;
    /* background: rgb(255,187,14);
    background: linear-gradient(90deg, rgba(255,187,14,1) 0%, rgba(255,213,0,1) 100%); */

    background: #fbe43955;


    box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.8), 0 8px 25px 0 rgba(0, 0, 0, 0.1);

    /* box-shadow: 0 1px 2px rgba(0,0,0,0.15); */
    transition: box-shadow 0.3s ease-in-out;
}

.childNewColor:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }



.section_divide_first{
    width: 100%;
    padding-bottom: 15vh;

    /* text-align: justify; */
}

.section_divide_second{
    width: 100%;
    padding-bottom: 50vh;
    padding-left: 2vh;
}


.h1TextTitle {
    font-family: 'Staatliches';
    font-size:7vh;
    margin: 0.5vh;
    color: #5d5d5e;
    background-color: none ;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    width: 100%;
 }


.h1TextTitle:hover {
    color:#1c1d20;
    text-decoration: underline;
 }


.section_divide_first h1 {
    font-family: 'Staatliches';
    font-size:9vh;

    color: #5d5d5e;


}

.h1TextMAX::before {
    color: #1c1d20;
    content: "Max Tehnika ";
  }

.h1ArrowText {
    display: flex;
    flex-direction: row;


}


.h1Arrow:nth-child(1) {

    transition-delay: 200ms;
}
.h1Arrow:nth-child(2) {

    transition-delay: 400ms;
}
.h1Arrow:nth-child(3) {

    transition-delay: 600ms;
}


.section_divide_first h2 {
    font-family: 'Staatliches';

    color: #1c1d20;

}

 h1 {
    font-family: 'Staatliches';
    font-size:7vh;
    margin: 0.5vh;
    color: #5d5d5e;
    background-color: #FBE339 ;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    padding: 7px;
}

h2 {
    font-family: 'Staatliches';
    margin: 1vh;
    font-size:3vh;
    color: #1c1d20;

}

.section_divide_first_2{
    display: flex;
    flex-direction: column;
    width: 100%;

}

.section_divide_first_2{
    display: flex;
    flex-direction: column;
    width: 100%;
}
/* .tractor_image {
    width: 100%;
    height: 100%;
    animation:y-axis-animation 10s ease-in-out 0s infinite alternate;
    animation-name: tractor;
} */

.canvas_div {
    height: 80%
}


.models_div{
    display: flex;
    flex-flow: row wrap;
   justify-content: center;
}

.model_canvas {
   height: 60px;
    width: 60px;
    padding: 2vh;
}

.model_canvas:active {
    transform: translateY(4px);
  }

  .clickPNG{
    width: 100px;
    height: 100px;
    position: absolute;
    margin-top: 100px;
    margin-left: -20px;
}

.logo-container{
        margin-top: 5vh;
        display: flex;
        align-content: flex-start;
        flex-direction: row  ;
        flex-wrap: wrap;
        width: 100%;
    };



.logo {

        padding:  0.5vh ;
        border-right: 2px solid black;
    }
    .logo:nth-child(1) {

        padding:  0.5vh ;
        transition-delay: 100ms;

    }
.logo:nth-child(2) {

        padding:  0.5vh ;
        transition-delay: 120ms;
    }
.logo:nth-child(3) {

        padding:  0.5vh ;
        transition-delay: 140ms;
    }
.logo:nth-child(4) {

        padding:  0.5vh ;
        transition-delay: 160ms;
    }
    .logo:nth-child(5) {

        padding:  0.5vh ;
        transition-delay: 180ms;
    }
    .logo:nth-child(6) {

        padding:  0.5vh ;
        transition-delay: 200ms;
    }
    .logo:nth-child(7) {

        padding:  0.5vh ;
        transition-delay: 220ms;
    }
    .logo:nth-child(8) {

        padding:  0.5vh ;
        transition-delay: 240ms;
    }
    .logo:nth-child(9) {

        padding:  0.5vh ;
        transition-delay: 260ms;
    }
    .logo:nth-child(10) {

        padding:  0.5vh ;
        transition-delay: 280ms;
    }
    .logo:nth-child(11) {

        padding:  0.5vh ;
        transition-delay: 300ms;
    }
    .logo:nth-child(12) {

        padding:  0.5vh ;
        transition-delay: 320ms;
    }
    .logo:nth-child(13) {

        padding:  0.5vh ;
        transition-delay: 340ms;
    }
    .logo:nth-child(14) {

        padding:  0.5vh ;
        transition-delay: 360ms;
    }
    .logo:nth-child(15) {

        padding:  0.5vh ;
        transition-delay: 380ms;
    }
    .logo:nth-child(16) {

        padding:  0.5vh ;
        transition-delay: 400ms;
    }
    .logo:nth-child(17) {

        padding:  0.5vh ;
        transition-delay: 420ms;
    }
    .logo:nth-child(18) {

        padding:  0.5vh ;
        transition-delay: 440ms;
    }
    .logo:nth-child(19) {

        padding:  0.5vh ;
        transition-delay: 460ms;
    }
    .logo:nth-child(20) {

        padding:  0.5vh ;
        transition-delay: 480ms;
    }
    .logo:nth-child(21) {

        padding:  0.5vh ;
        transition-delay: 500ms;
    }
    .logo:nth-child(22) {

        padding:  0.5vh ;
        transition-delay: 520ms;
    }
    .logo:nth-child(23) {

        padding:  0.5vh ;
        transition-delay: 540ms;
    }
    .logo:nth-child(24) {

        padding:  0.5vh ;
        transition-delay: 560ms;
    }
    .logo:nth-child(25) {

        padding:  0.5vh ;
        transition-delay: 580ms;
    }
    .logo:nth-child(26) {

        padding:  0.5vh ;
        transition-delay: 600ms;
    }
    .logo:nth-child(27) {

        padding:  0.5vh ;
        transition-delay: 620ms;
    }
    .logo:nth-child(28) {

        padding:  0.5vh ;
        transition-delay: 640ms;
    }
    .logo:nth-child(29) {

        padding:  0.5vh ;
        transition-delay: 660ms;
    }
    .logo:nth-child(30) {

        padding:  0.5vh ;
        transition-delay: 680ms;
    }
    .logo:nth-child(31) {

        padding:  0.5vh ;
        transition-delay: 700ms;
    }
    .logo:nth-child(32) {

        padding:  0.5vh ;
        transition-delay: 720ms;
    }
    .logo:nth-child(33) {

        padding:  0.5vh ;
        transition-delay: 740ms;
    }
    .logo:nth-child(34) {

        padding:  0.5vh ;
        transition-delay: 760ms;
    }
    .logo:nth-child(35) {

        padding:  0.5vh ;
        transition-delay: 780ms;
    }
    .logo:nth-child(36) {

        padding:  0.5vh ;
        transition-delay: 800ms;
    }
    .logo:nth-child(37) {

        padding:  0.5vh ;
        transition-delay: 820ms;
    }
    .logo:nth-child(38) {

        padding:  0.5vh ;
        transition-delay: 840ms;
    }
    .logo:nth-child(39) {

        padding:  0.5vh ;
        transition-delay: 840ms;
    }



    .img_logo  {
        width: 80px;
        height: 80px;
    }



.hidden {
    opacity: 0;
    transition: all 1s;
    filter: blur(5px);
    transform:translateX(-50px)
}

.show {
    opacity: 1;
    transition: all 1s;
    filter: blur(0px);
    transform:translateX(0)
}

.hidden2 {
    opacity: 0;
    transition: all 1s;
    filter: blur(5px);
    transform:translateX(-80px)
}

.show2 {
    opacity: 1;
    transition: all 1s;
    filter: blur(0px);
    transform:translateX(0)
}

.hidden3{

    transition: all 1s;

    transform: scale(0.7);
}

.show3 {
    opacity: 1;
    transition: all 1s;

    transform: scale(1);
}



.image_div{
    max-width: 800px;
    min-width: 200px;
    max-height: 500px;

}

.box_image {
    position: absolute;
    width: 50%;
    height: auto;
    filter: drop-shadow(10px 5px 7px black);

}

.image_part{
    width: 100%;
    height: auto;
    filter: drop-shadow(10px 5px 7px black);
}



.image_h1{
    position: absolute ;
    font-size: 2vh;
    width: 45%;
    margin-left: 50%;

}




/* .album_images text{
    width: 30%;
    align-self: center;
    color: white;
    font-family: monospace;
 } */


/* .logo_image {
    width: 100%;
    height: 100%;
 } */

@media only screen and (min-width: 768px) {



    .child {
        padding-top: 10vh;
        padding-bottom: 10vh;
        padding-left: 7vh;
        flex-flow: row ;

    }

    .childNewColor {
        padding-top: 10vh;
        padding-bottom: 10vh;
        padding-left: 7vh;
        flex-flow: row ;
        margin-bottom: 0px;
    }

    .svg_div_animation{
        position: relative;
        width: 100%;
        height: 500px;
        margin-left: 0%;
    }

    h1 {
        font-family: 'Staatliches';
        font-size:9vh;
        margin: 0.5vh;
        color: #5d5d5e;
        padding: 10px;


    }

    h2 {
        font-family: 'Staatliches';
        margin: 1vh;
        font-size:4vh;
        color: #1c1d20;

    }

    .h1TextTitle {

        width: 80%;
     }



    .section_divide_first{
        width: 40%;
    }

    .section_divide_first h1 {

        font-size:9vh;




    }
    .section_divide_second{
        width: 60%;
        padding-bottom: 0;
        padding-left: 0;
    }

    .section_divide_first_2{
        width: 50%;
    }
    .section_divide_second_2{
        width: 50%;
    }

    .canvas_div {
        height: 300px;
    }


    .model_canvas {
        width: 100px;
        height: 100px;
        padding-left: 4vh;
        padding-right: 4vh;
     }

     .clickPNG{
        width: 100px;
        height: 100px;
        position: absolute;
        margin-top: 80px;
        margin-left: -80px;


    }



     .logo-container{
        margin-top: 5vh;
        display: flex;
        align-content: flex-start;
        flex-direction: row  ;
        flex-wrap: wrap;
        width: 100%;


    };

    .logo {

        padding:  1vh ;
    }

    .img_logo  {
        width: 80px;
        height: 80px;
    }


    .box_image {

        width: 30%;


    }





    .image_h1{
        font-size: 6vh;
        margin-left: 40%;
        padding: 2vh;

    }


    .section_logo_font{
        font-size:2.5vh;
    }

    .image_section {
        margin-top: 200px;
    }
  }
