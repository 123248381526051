

hr {
  border-color: #5d5d5e;
  width: 95%;
  border: 4px solid #5d5d5e;
  border-top-width: 1px;
}

.footer-distributed{



  /* background: #ffc400c4; */
  background: #f8d616;

  box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.8), 0 8px 25px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100% ;
  text-align: left;
  font: bold 16px sans-serif;
  padding:50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
  display: inline-block;
  vertical-align: top;

}

/* Footer left */

.footer-distributed .footer-left{
  width: 40%;
}

/* The company logo */

.footer-distributed h3{
  color:  #5d5d5e;
  font: normal 36px 'Open Sans', cursive;
  margin: 0;
}

.footer-distributed h3 span{
  color:  #5d5d5e;
}

/* Footer links */

.footer-distributed .footer-links{
  color:  #5d5d5e;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a{
  display:inline-block;
  line-height: 1.8;
  font-weight:400;
  text-decoration: none;
  color:  inherit;
}

.footer-distributed .footer-company-name{
  color:  #5d5d5e;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
  width: 35%;
}

.footer-distributed .footer-center i{
  background-color:  #33383b;
  color: #5d5d5e;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p{
  display: inline-block;
  color: #5d5d5e;
  font-weight:400;
  vertical-align: middle;
  margin:0;
}

.footer-distributed .footer-center p span{
  display:block;
  font-weight: normal;
  font-size:14px;
  line-height:2;
}

.footer-distributed .footer-center p a{
  color:  #1c1d20;
  text-decoration: underline;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #1c1d20;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
  width: 20%;
}

.footer-distributed .footer-company-about{
  line-height: 20px;
  color:  #1c1d20;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  width: 100%;
}

.footer-distributed .footer-company-about span{
  display: block;
  color:  #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons{
  margin-top: 25px;
}

.footer-distributed .footer-icons a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color:  #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */
.div_icon_first {
   display: flex;
   flex-flow: row wrap;
   margin-bottom: 15px;
   margin-top: 20px;

}

.div_icon_border{
  position: relative;
  align-content: center;

  margin-top: -10px;
  width: 5.5vh;
  height: 5.5vh;
}

.svg_icon_first {

  font: inherit;
  display: block;
  margin: auto;
  width: 3.5vh;
  height: 3.5vh;
  margin-top: 6px;
  color:"#5d5d5e";




}

.section_icons{
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;

}

.div_icon {
  position: relative;
  align-content: center;


  width: 5vh;
  height: 5vh;
}

.svg_icon {

  font: inherit;
  display: block;
  margin: auto;
padding-top: 0.5vh;
  width: 4vh;
  height: 4vh;
}


@media (max-width: 880px) {

  .footer-distributed{
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;

  }

  .footer-distributed .footer-center i{
    margin-left: 0;
  }
  .div_icon_first {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 15px;
    margin-top: 10px;
 }

 .div_icon_first {
  display: flex;
  flex-direction: column ;
  align-items: center;
  justify-content: center;
 padding: 10px;

}


.footer-distributed .footer-company-about{


 color:  #1c1d20;
 font-size: 20px;
 font-weight: normal;
 margin: 0;
 width: 100%;
}

}
